import React from 'react'   
import imagen_1 from "../../Assets/amoblamientos/SaladeEstarDani.jpg"
import imagen_2 from "../../Assets/amoblamientos/SaladeEstarCA.jpg"
import imagen_3 from "../../Assets/amoblamientos/QuinchoDani.jpg"
import imagen_4 from "../../Assets/amoblamientos/PaneldeTvCA.jpg"
import imagen_5 from "../../Assets/amoblamientos/CocinaDani.jpg"
import imagen_6 from "../../Assets/amoblamientos/DormitorioCA.jpg"
import imagen_7 from "../../Assets/amoblamientos/MostradorPiscinasdeleste.jpg"
import imagen_8 from "../../Assets/amoblamientos/TiendaOndazul.jpg"
import imagen_9 from "../../Assets/amoblamientos/CafeteriaHernandarias.jpg"

const Amoblamientos = () => {
    return (
        <div className="row" >
        <div className="col-md-4 mb-4">
            <div className="portafolio-item image-zoom mb-4 d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_1} alt="" />
    
                </div>
                <a href={imagen_1} data-fancybox="gallery" className='iconbox'>
                    <p>Sala de Estar Dani</p>
                </a>
            </div>
    
            <div className="portafolio-item image-zoom mt-4 d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_2} alt="" />
    
                </div>
                <a href={imagen_2} data-fancybox="gallery" className='iconbox'>
                    <p>Sala de Estar CA</p>
                </a>
            </div>

            <div className="portafolio-item image-zoom mt-4 d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_7} alt="" />
    
                </div>
                <a href={imagen_7} data-fancybox="gallery" className='iconbox'>
                    <p>Mostrador Piscinas del Este</p>
                </a>
            </div>
    
        </div>
        <div className="col-md-4 mb-4">
            <div className="portafolio-item image-zoom mb-4 d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_3} alt="" />
    
                </div>
                <a href={imagen_3} data-fancybox="gallery" className='iconbox'>
                    <p>Quincho Dani</p>
                </a>
            </div>
    
            <div className="portafolio-item image-zoom mt-4 d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_4} alt="" />
    
                </div>
                <a href={imagen_4} data-fancybox="gallery" className='iconbox'>
                    <p>Panel de Tv CA</p>
                </a>
            </div>

            <div className="portafolio-item image-zoom mt-4 d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_8} alt="" />
    
                </div>
                <a href={imagen_8} data-fancybox="gallery" className='iconbox'>
                    <p>Tienda Ondazul</p>
                </a>
            </div>
    
        </div>
        <div className="col-md-4 mb-4">
            <div className="portafolio-item image-zoom mb-4  d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_5} alt="" />
    
                </div>
                <a href={imagen_5} data-fancybox="gallery" className='iconbox'>
                    <p>Cocina Dani</p>
                </a>
            </div>
    
            <div className="portafolio-item image-zoom mt-4  d-flex align-items-end">
                <div className="image-zoom-warpper">
                    <img src={imagen_6} alt="" />
    
                </div>
                <a href={imagen_6} data-fancybox="gallery" className='iconbox'>
                    <p>Dormitorio CA</p>
                </a>
            </div>

            <div className="portafolio-item image-zoom mt-4 d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_9} alt="" />
    
                </div>
                <a href={imagen_9} data-fancybox="gallery" className='iconbox'>
                    <p>Cafeteria Hernandarias</p>
                </a>
            </div>
    
        </div>

    </div>
      )
}

export default Amoblamientos