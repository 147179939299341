import React from 'react'
import nosotrosImg from "../Assets/nosotros.jpg";

const Nosotros = () => {
  return (
    <section id='nosotros' className='mt-5 mb-5'>
      <div className="container">

        <div className="row">
          <div className="col-12 mt-5">
            <div className='text-center'>
              <h1 className='display-1 fw-bold mb-4 '>Nosotros</h1>
            </div>

            <div className='d-flex justify-content-center'>
              <p className='col-10 mb-5 fw-light justificar'>
                Somos una empresa dedicada al ámbito del Diseño, la Arquitectura y la Construcción, compuesta por un equipo de Arquitectos y Diseñadores que mediante el trabajo en conjunto y la pasión, busca brindar un servicio de alta calidad y excelencia.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center  ">
          <div className="col-10 col-lg-5">
            <img className='imagen' src={nosotrosImg} alt="" />
          </div>
          <div className="col-10 col-lg-5">
            <h1 className='mt-4 mt-lg-0 fw-bold '>New Design</h1>
            <p className='mb-3 fw-light justificar'>Estamos instalados en el mercado de la Arquitectura hace 4 años, mejorando la calidad de vida de nuestros clientes mediante los servicios brindados tales como los Proyectos Arquitectónicos, Construcciones Civiles, Diseño de Interiores y elaboración de mobiliarios. <a href="https://maps.app.goo.gl/rzCS7mj9CrDda8gHA" target="_blank" rel="noopener noreferrer"> <b>Ubicacion</b> </a></p>

            <div className="d-flex col-12">
              <div className=" me-2">
                <i class='bx bx-pin' ></i>
              </div>
              <div >
                <h5 className='fw-light'>Mision</h5>
                <p className='fw-light justificar'>Buscamos brindar seguridad y una excelente experiencia proyectual y de construcción a nuestros clientes, mediante procesos basados en la agilidad y eficacia de diseño integral de los ambientes. </p>
              </div>
            </div>
            <div className="d-flex col-12" >
              <div className=" me-2">
                <i class='bx bx-paper-plane'></i>
              </div>
              <div >
                <h5 className='fw-light'>Vision</h5>
                <p className='fw-light justificar'> Apuntamos a mejorar cada vez mas nuestra calidad de servicios, por sobre todo generar vínculos duraderos con nuestros clientes y así ser referentes de la Arquitectura en la región y el Pais. </p>

              </div>
            </div>
            <div className="d-flex col-12">
              <div className=" me-2">
                <i class='bx bx-award' ></i>
              </div>
              <div >
                <h5 className='fw-light'>Valores</h5>
                <p className='fw-light'>  Tenemos como valores principales la responsabilidad y el compromiso con nuestros clientes brindando seguridad a las inversiones.</p>

              </div>
            </div>
          </div>
        </div>


      </div>
    </section>
  )
}

export default Nosotros