import React from 'react'
import imagen_1 from "../../Assets/construcciones/Dani.jpg"
import imagen_2 from "../../Assets/construcciones/A5.jpg"
import imagen_3 from "../../Assets/construcciones/Geraldine.jpg"
import imagen_4 from "../../Assets/construcciones/piscinas.jpg"
import imagen_5 from "../../Assets/construcciones/UtexSport.jpg"
import imagen_6 from "../../Assets/construcciones/Weber.jpg"


const Construcciones = () => {

    return (
        <div className="row" >
        <div className="col-md-4 mb-4">
            <div className="portafolio-item image-zoom mb-4 d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_1} alt="" />
    
                </div>
                <a href={imagen_1} data-fancybox="gallery" className='iconbox'>
                    <p>Casa Dani</p>
                </a>
            </div>
    
            <div className="portafolio-item image-zoom mt-4 d-flex align-items-end">
                <div className="image-zoom-warpper">
                    <img src={imagen_2} alt="" />
    
                </div>
                <a href={imagen_2} data-fancybox="gallery" className='iconbox'>
                <p>Casa A5</p>
                </a>
            </div>
    
        </div>
        <div className="col-md-4 mb-4">
            <div className="portafolio-item image-zoom mb-4 d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_3} alt="" />
    
                </div>
                <a href={imagen_3} data-fancybox="gallery" className='iconbox'>
                    <p>Casa Geraldine</p>
                </a>
            </div>
    
            <div className="portafolio-item image-zoom mt-4 d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_4} alt="" />
    
                </div>
                <a href={imagen_4} data-fancybox="gallery" className='iconbox'>
                    <p>Piscinas del Este</p>
                </a>
            </div>
    
        </div>
        <div className="col-md-4 mb-4">
            <div className="portafolio-item image-zoom mb-4 d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_5} alt="" />
    
                </div>
                <a href={imagen_5} data-fancybox="gallery" className='iconbox'>
                    <p>UtexSport</p>
                </a>
            </div>
    
            <div className="portafolio-item image-zoom mt-4 d-flex align-items-center">
                <div className="image-zoom-warpper">
                    <img src={imagen_6} alt="" />
    
                </div>
                <a href={imagen_6} data-fancybox="gallery" className='iconbox'>
                    <p>Casa Weber</p>
                </a>
            </div>
    
        </div>
    </div>
      )

}

export default Construcciones