import React, { useState, useEffect } from 'react';
import clienteImg_1 from "../Assets/clientes/ondazul.png";
import clienteImg_2 from "../Assets/clientes/utex.png";
import clienteImg_3 from "../Assets/clientes/piscinas.png";
import clienteImg_4 from "../Assets/clientes/pdo.png";
import clienteImg_5 from "../Assets/clientes/pocho.png";
import clienteImg_6 from "../Assets/clientes/corazon.png";
import clienteImg_7 from "../Assets/clientes/1306.png";
import clienteImg_8 from "../Assets/clientes/greta.png";
const Clientes = () => {
    const [clientesCount, setClientesCount] = useState(0);
    const [proyectosCount, setProyectosCount] = useState(0);
    const [countStarted, setCountStarted] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = document.getElementById('clientes');
            if (element) {
                const { top } = element.getBoundingClientRect();
                if (top < window.innerHeight * 0.8 && !countStarted) {
                    setCountStarted(true);

                    const clientesInterval = setInterval(() => {
                        setClientesCount(prevCount => {
                            if (prevCount >= 54) {
                                clearInterval(clientesInterval);
                                return 54;
                            }
                            return prevCount + 3;
                        });
                    }, 50);

                    const proyectosInterval = setInterval(() => {
                        setProyectosCount(prevCount => {
                            if (prevCount >= 100) {
                                clearInterval(proyectosInterval);
                                return 100;
                            }
                            return prevCount + 3;
                        });
                    }, 50);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [countStarted]);

    return (
        <section id='clientes' className='mt-5 mb-5 pt-5'>
            <div className="container">
                <div className="row">
                    <div className="col-12 mt-5">
                        <div className='text-center'>
                            <h1 className='display-1 fw-light mb-5'>Clientes</h1>
                        </div>
                    </div>
                </div>

                <div className="row mb-0 mb-md-5 justify-content-center">
                    <div className="col-4 col-md-3 d-flex justify-content-center mb-5 m-3 m-md-0">
                        <img className='imagenCliente' src={clienteImg_1} alt="" />
                    </div>
                    <div className="col-4 col-md-3 d-flex justify-content-center mb-5 m-3 m-md-0">
                        <img className='imagenCliente' src={clienteImg_2} alt="" />
                    </div>
                    <div className="col-4 col-md-3 d-flex justify-content-center mb-5 m-3 m-md-0">
                        <img className='imagenCliente' src={clienteImg_3} alt="" />
                    </div>
                    <div className="col-4 col-md-3 d-flex justify-content-center mb-5 m-3 m-md-0">
                        <img className='imagenCliente' src={clienteImg_4} alt="" />
                    </div>
                </div>
                <div className="row mb-5 justify-content-center">
                    <div className="col-4 col-md-3 d-flex justify-content-center mb-5 m-3 m-md-0">
                        <img className='imagenCliente' src={clienteImg_5} alt="" />
                    </div>
                    <div className="col-4 col-md-3 d-flex justify-content-center mb-5 m-3 m-md-0">
                        <img className='imagenCliente' src={clienteImg_6} alt="" />
                    </div>
                    <div className="col-4 col-md-3 d-flex justify-content-center mb-5 m-3 m-md-0">
                        <img className='imagenCliente' src={clienteImg_7} alt="" />
                    </div>
                    <div className="col-4 col-md-3 d-flex justify-content-center mb-5 m-3 m-md-0">
                        <img className='imagenCliente' src={clienteImg_8} alt="" />
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-4 col-sm-6 d-flex align-items-center flex-column mb-5">
                        <h1 className='display-4'>{clientesCount}+</h1>
                        <h5 className='mt-3 mb-3 fw-light'>Clientes</h5>
                    </div>
                    <div className="col-md-4 col-sm-6 d-flex align-items-center flex-column mb-5">
                        <h1 className='display-4'>{proyectosCount}+</h1>
                        <h5 className='mt-3 mb-3 fw-light'>Proyectos</h5>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Clientes;
