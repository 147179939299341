import React from 'react'
import imagen_1 from "../../Assets/proyectos/celina.jpg"
import imagen_2 from "../../Assets/proyectos/clasica.jpg"
import imagen_3 from "../../Assets/proyectos/clau.jpg"
import imagen_4 from "../../Assets/proyectos/geraldine.jpg"
import imagen_5 from "../../Assets/proyectos/seidhom.jpg"
import imagen_6 from "../../Assets/proyectos/weber.jpg"


const Proyectos = () => {
    return (
        <div className="row">
            <div className="col-md-4 mb-4">
                <div className="portafolio-item image-zoom mb-4">
                    <div className="image-zoom-warpper">
                        <img src={imagen_1} alt="" />

                    </div>
                    <a href={imagen_1} data-fancybox="gallery" className='iconbox'>
                        <p>Casa Celina</p>
                    </a>
                </div>

                <div className="portafolio-item image-zoom mt-4">
                    <div className="image-zoom-warpper">
                        <img src={imagen_2} alt="" />

                    </div>
                    <a href={imagen_2} data-fancybox="gallery" className='iconbox'>
                    <p>Casa Clasica</p>
                    </a>
                </div>

            </div>
            <div className="col-md-4 mb-4">
                <div className="portafolio-item image-zoom mb-4">
                    <div className="image-zoom-warpper">
                        <img src={imagen_3} alt="" />

                    </div>
                    <a href={imagen_3} data-fancybox="gallery" className='iconbox'>
                    <p>Casa Clau</p>
                    </a>
                </div>

                <div className="portafolio-item image-zoom mt-4">
                    <div className="image-zoom-warpper">
                        <img src={imagen_4} alt="" />

                    </div>
                    <a href={imagen_4} data-fancybox="gallery" className='iconbox'>
                    <p>Casa Geraldine</p>
                    </a>
                </div>

            </div>
            <div className="col-md-4 mb-4">
                <div className="portafolio-item image-zoom mb-4">
                    <div className="image-zoom-warpper">
                        <img src={imagen_5} alt="" />

                    </div>
                    <a href={imagen_5} data-fancybox="gallery" className='iconbox'>
                    <p>Casa Seidhom</p>
                    </a>
                </div>

                <div className="portafolio-item image-zoom mt-4">
                    <div className="image-zoom-warpper">
                        <img src={imagen_6} alt="" />

                    </div>
                    <a href={imagen_6} data-fancybox="gallery" className='iconbox'>
                    <p>Casa Weber</p>
                    </a>
                </div>

            </div>
        </div>
    )
}

export default Proyectos