import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import VisibilitySensor from 'react-visibility-sensor';

const Header = () => {
    const [isVisible, setIsVisible] = useState(false);

    const fadeIn = useSpring({
        opacity: isVisible ? 1 : 0,
        from: { opacity: 0 },
        config: { duration: 800 },
    });

    return (
        <section id='inicio' className='d-flex align-items-center text-center zoom-effect'>
            <div className='container'>
                <div className='row min-vh-100 align-content-end'>
                    <div className="col-12 text-white">
                        <VisibilitySensor
                            partialVisibility
                            onChange={(visible) => {
                                if (visible && !isVisible) {
                                    setIsVisible(true);
                                }
                            }}
                        >
                            <animated.div style={fadeIn}>
                                <div>
                                    <h1 className='titulo-header'>NEW DESIGN</h1>
                                </div>
                                <div>
                                    <h1 className='titulo-header-sub'>ARQUITECTURA</h1>
                                </div>


                                <div className='col-12'>
                                    <a href="#nosotros"><i className='bx bx-chevron-down mb-4' ></i></a>
                                </div>
                            </animated.div>
                        </VisibilitySensor>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;